import React, { Fragment, useState, useEffect, useCallback } from "react";
import { H5, H4, H6, Btn, P } from "../../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Label,
  Form,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { toast } from "react-toastify";
import awsExports from "../../../aws-exports";
import AWS from "aws-sdk";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import DatePicker from "react-datepicker";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import {
  S3_BUCKET,
  REGION,
  ASSET_OWNER_FOLDER,
  SECRETACCESSKEY,
  ACCESSKEYID,
  USERPOOLID,
} from "../../../Config/Config";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { filterEventStoreDefs } from "@fullcalendar/react";
import {
  limit,
} from "../../../Config/Config";

Amplify.configure(awsExports);

const TestResultContains = () => {
  const state = useLocation();
  console.log(state);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [assets, setAssets] = useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [assetDemo, setAssetDemo] = useState([]);
  const [assetsDropdown, setAssetsDropdown] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataDate, setFilteredDataDate] = useState([]);
  const [assetsName, setAssetsName] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(false);
  const LoginUserGroup = localStorage.getItem("groups");
  const [assetS, setAssetS] = useState(false);
  const [date, setDate] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [sd, setSD] = useState([]);
  const [ed, setED] = useState([]);
  //const [limit, setLimit] = useState(100000);

  useEffect(() => {
    if (state.state) {
      fetchTestResult(state.state.site.id);
    }
    if (LoginUserGroup === "site_owner") {
      fetchAssetsAO();
    } else if (LoginUserGroup === "site_user") {
      fetchAssetSU();
    }
    else {
      fetchAssetSP();
    }
    //fetchAssets();
  }, []);

  //--- Assets Name for Ao ----

  async function fetchAssetsAO() {
    //setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        console.log(
          "first token count",
          result.data.siteDetailsByAssetownerID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken =
                results.data.siteDetailsByAssetownerID.nextToken;
              console.log("nextToken", nextToken);
              console.log(
                results.data.siteDetailsByAssetownerID.items.length
              );
              if (
                results.data.siteDetailsByAssetownerID.items.length > 0
              ) {
                assets.push(
                  results.data.siteDetailsByAssetownerID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const siteDetails = mergeAssets;

          //--
          let nextToken1 = "";
          let assets1 = [];
          siteDetails.map(async (siteee) => {
            await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: siteee.id,
                limit: limit,
              },
            })
              .then(async (result) => {
                let assetDatas = result.data.assetsBySitedetailsID.items;
                nextToken1 = result.data.assetsBySitedetailsID.nextToken;
                console.log(
                  "first token count",
                  result.data.assetsBySitedetailsID.items.length
                );
                console.log("nextToken 1", nextToken1);
                if (result.data.assetsBySitedetailsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await API.graphql({
                    query: queries.assetsBySitedetailsID,
                    variables: {
                      sitedetailsID: siteee.id,
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken1 = results.data.assetsBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken1);
                      console.log(results.data.assetsBySitedetailsID.items.length);
                      if (results.data.assetsBySitedetailsID.items.length > 0) {
                        assets1.push(results.data.assetsBySitedetailsID.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  console.log(mergeAssets1);
                  //--
                  const sort = mergeAssets1.sort(
                    (a, b) => a.fittingNumber - b.fittingNumber
                  );
                  console.log(sort);
                  setAssetsName(sort);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //----Assets Name for Site User

  async function fetchAssetSU() {
    //---
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
    })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        console.log(
          "first token count",
          result.data.linkUsersAndSitesByUserdetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(
                results.data.linkUsersAndSitesByUserdetailsID.items.length
              );
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const siteDetails = mergeAssets;

          //--
          let nextToken1 = "";
          let assets1 = [];
          siteDetails.map(async (siteee) => {
            await API.graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: siteee.sitedetailsID,
                limit: limit,
              },
            })
              .then(async (result) => {
                let assetDatas = result.data.assetsBySitedetailsID.items;
                nextToken1 = result.data.assetsBySitedetailsID.nextToken;
                console.log(
                  "first token count",
                  result.data.assetsBySitedetailsID.items.length
                );
                console.log("nextToken 1", nextToken1);
                if (result.data.assetsBySitedetailsID.items.length > 0) {
                  assets1.push(assetDatas);
                }
                while (nextToken1 !== null) {
                  await API.graphql({
                    query: queries.assetsBySitedetailsID,
                    variables: {
                      sitedetailsID: siteee.sitedetailsID,
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                    .then((results) => {
                      nextToken1 = results.data.assetsBySitedetailsID.nextToken;
                      console.log("nextToken", nextToken1);
                      console.log(results.data.assetsBySitedetailsID.items.length);
                      if (results.data.assetsBySitedetailsID.items.length > 0) {
                        assets1.push(results.data.assetsBySitedetailsID.items);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets1);
                if (assets1.length > 0) {
                  const mergeAssets1 = assets1.flat(1);
                  console.log(mergeAssets1);
                  //--
                  const sort = mergeAssets1.sort(
                    (a, b) => a.fittingNumber - b.fittingNumber
                  );
                  console.log(sort);
                  setAssetsName(sort);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //----Assets Name for Service provider

  async function fetchAssetSP() {
    //--
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        limit: limit,
      },
    })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        console.log(
          "first token count",
          result.data.linkUsersAndSitesByUserdetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: LoginUserDetails.id,
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(
                results.data.linkUsersAndSitesByUserdetailsID.items.length
              );
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const siteDetails = mergeAssets;

          //--
          let nextToken1 = "";
          let assets1 = [];
          await API.graphql({
            query: queries.listSiteDetails,
            variables: {
              limit: limit,
            },
          })
            .then(async (result) => {
              let assetDatas = result.data.listSiteDetails.items;
              nextToken1 = result.data.listSiteDetails.nextToken;
              console.log(
                "first token count",
                result.data.listSiteDetails.items.length
              );
              console.log("nextToken 1", nextToken1);
              if (result.data.listSiteDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await API.graphql({
                  query: queries.listSiteDetails,
                  variables: {
                    limit: limit,
                    nextToken: nextToken1,
                  },
                  authMode: "API_KEY",
                })
                  .then((results) => {
                    nextToken1 = results.data.listSiteDetails.nextToken;
                    console.log("nextToken", nextToken1);
                    console.log(results.data.listSiteDetails.items.length);
                    if (results.data.listSiteDetails.items.length > 0) {
                      assets1.push(results.data.listSiteDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                //--
                const siteDetail = mergeAssets1.filter((item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });

                //const filterdata = selectedAssetOwnerGlobal ? data : siteDetail;
                console.log(siteDetail);
                console.log(siteDetail);
                //--
                let nextToken2 = "";
                let assets2 = [];
                siteDetail.map(async (siteee) => {
                  await API.graphql({
                    query: queries.assetsBySitedetailsID,
                    variables: {
                      sitedetailsID: siteee.id,
                      limit: limit,
                    },
                  })
                    .then(async (result) => {
                      let assetDatas = result.data.assetsBySitedetailsID.items;
                      nextToken2 = result.data.assetsBySitedetailsID.nextToken;
                      console.log(
                        "first token count",
                        result.data.assetsBySitedetailsID.items.length
                      );
                      console.log("nextToken 1", nextToken2);
                      if (result.data.assetsBySitedetailsID.items.length > 0) {
                        assets2.push(assetDatas);
                      }
                      while (nextToken2 !== null) {
                        await API.graphql({
                          query: queries.assetsBySitedetailsID,
                          variables: {
                            sitedetailsID: siteee.id,
                            limit: limit,
                            nextToken: nextToken2,
                          },
                          authMode: "API_KEY",
                        })
                          .then((results) => {
                            nextToken2 = results.data.assetsBySitedetailsID.nextToken;
                            console.log("nextToken", nextToken2);
                            console.log(results.data.assetsBySitedetailsID.items.length);
                            if (results.data.assetsBySitedetailsID.items.length > 0) {
                              assets2.push(results.data.assetsBySitedetailsID.items);
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                      console.log(assets2);
                      if (assets2.length > 0) {
                        const mergeAssets2 = assets2.flat(1);
                        console.log(mergeAssets2);
                        //--
                        const sort = mergeAssets2.sort(
                          (a, b) => a.fittingNumber - b.fittingNumber
                        );
                        console.log(sort);
                        setAssetsName(sort);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchAssets() {
    //setLoading(true);
    //-
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.listAssets,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.listAssets.items;
        nextToken = result.data.listAssets.nextToken;
        console.log(
          "first token count",
          result.data.listAssets.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listAssets.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.listAssets,
            variables: {
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.listAssets.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listAssets.items.length);
              if (results.data.listAssets.items.length > 0) {
                assets.push(results.data.listAssets.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setAssetsName(mergeAssets);
          //setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAssets(aid) {
    const asset = assetsName.find((item) => item.id === aid);
    return asset ? asset.fittingNumber : "";
  }

  async function fetchTestResult(siteId) {
    setLoading(true);
    const result = await API.graphql({
      query: queries.listAssetTemplates,
      variables: {
        limit: limit,
        filter: {
          categoriesID: {
            eq: state.state.category.id,
          },
        },
      },
      authMode: "API_KEY",
    });
    const assettemplate = result.data.listAssetTemplates.items;
    console.log(assettemplate);
    let catId = [];
    assettemplate.map((item) => {
      catId.push(item.id);
    });
    //--
    let nextToken = "";
    let assets = [];
    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        filter: {
          status: {
            ne: "delete",
          }
        },
        limit: limit,
      },
      authMode: "API_KEY",
    })
      .then(async (result) => {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken = result.data.assetsBySitedetailsID.nextToken;
        console.log(
          "first token count",
          result.data.assetsBySitedetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await API.graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              filter: {
                status: {
                  ne: "delete",
                }
              },
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          })
            .then((results) => {
              nextToken = results.data.assetsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.assetsBySitedetailsID.items.length);
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const asset = mergeAssets;
          const assetData = asset.filter((item) => {
            const _matchSites = catId?.find((id) => id === item?.assettemplateID);
            if (_matchSites) {
              return item;
            }
          });
          console.log(assetData);
          const sort = assetData.sort((a, b) => a.fittingNumber - b.fittingNumber);
          console.log(sort);
          setAssetsDropdown(sort);
          const testReport = [];
          let assetId = [];
          if (assetData.length > 0) {
            assetData.map((item) => {
              assetId.push(item.id);
            });
            //--

            let nextToken1 = "";
            assetData.map(async (item) => {
              let assets1 = [];
              await API.graphql({
                query: queries.testingsByAssetsID,
                variables: {
                  limit: limit,
                  assetsID: item.id,
                },
                authMode: "API_KEY",
              })
                .then(async (result) => {
                  let assetDatas = result.data.testingsByAssetsID.items;
                  nextToken1 = result.data.testingsByAssetsID.nextToken;
                  console.log(
                    "first token count",
                    result.data.testingsByAssetsID.items.length
                  );
                  console.log("nextToken 1", nextToken1);
                  if (result.data.testingsByAssetsID.items.length > 0) {
                    assets1.push(assetDatas);
                  }
                  while (nextToken1 !== null) {
                    await API.graphql({
                      query: queries.testingsByAssetsID,
                      variables: {
                        limit: limit,
                        assetsID: item.id,
                        nextToken: nextToken1,
                      },
                      authMode: "API_KEY",
                    })
                      .then((results) => {
                        nextToken1 = results.data.testingsByAssetsID.nextToken;
                        console.log("nextToken", nextToken1);
                        console.log(results.data.testingsByAssetsID.items.length);
                        if (results.data.testingsByAssetsID.items.length > 0) {
                          assets1.push(results.data.testingsByAssetsID.items);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  console.log(assets1);
                  if (assets1.length > 0) {
                    const mergeAssets1 = assets1.flat(1);
                    console.log(mergeAssets1);
                    //--
                    const testing = mergeAssets1;
                    console.log(testing);
                    {/*
                  const testingData = testing.filter((item) => {
                    const _matchSites = assetId?.find(
                      (id) => id === item?.assetsID
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                */}
                    //console.log(testingData);
                    if (testing.length > 0) {
                      testing.map((test) => {
                        const custom = JSON.parse(test.customFields);
                        const customFormatted =
                          custom &&
                          custom.map((item) => {
                            const key = Object.keys(item)[0];
                            const formattedValue = item[key];
                            return `${key} : ${formattedValue}`;
                          });
                        let customFieldsFormatted = [];
                        if (customFormatted !== null) {
                          customFieldsFormatted = customFormatted.join("\n");
                        } else {
                          console.error("customFormatted is null.");
                        }

                        testReport.push({
                          id: test.id,
                          createdAt: test.createdAt,
                          asset: test.assetsID,
                          testOn: test.testOn,
                          testResult: test.testResult,
                          nextTest: test.nextTestSchedule,
                          customfields: customFieldsFormatted,
                          remarks: test.additionalInformation,
                        });
                      });
                      const sort = testReport.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      );
                      console.log(sort);
                      setAssetDemo(sort);
                      console.log(testReport);
                      setAssets(cloneDeep(sort.slice(0, countPerPage)));
                      setLoading(false);
                    } else {
                      setAssetDemo(testReport);
                      console.log(testReport);
                      setAssets(cloneDeep(testReport.slice(0, countPerPage)));
                      setLoading(false);
                    }
                  }
                  else {
                    setAssetDemo([]);
                    setAssets(cloneDeep([].slice(0, countPerPage)));
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            });

          }
          else {
            setAssetDemo([]);
            setAssets(cloneDeep([].slice(0, countPerPage)));
            setLoading(false);
          }
        }
        else {
          setAssetDemo([]);
          setAssets(cloneDeep([].slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    {/*
    await API.graphql({
      query: queries.assetsBySitedetailsID,
      variables: {
        sitedetailsID: siteId,
        limit: 100000,
      },
      authMode: "API_KEY",
    })
      .then((result) => {
        console.log(result);
        const asset = result.data.assetsBySitedetailsID.items;
        const assetData = asset.filter((item) => {
          const _matchSites = catId?.find((id) => id === item?.assettemplateID);
          if (_matchSites) {
            return item;
          }
        });
        console.log(assetData);
        const sort = assetData.sort((a, b) => a.fittingNumber - b.fittingNumber);
        console.log(sort);
        setAssetsDropdown(sort);
        const testReport = [];
        let assetId = [];
        if (assetData.length > 0) {
          assetData.map((item) => {
            assetId.push(item.id);
          });
          API.graphql({
            query: queries.listTestings,
            variables: {
              limit: 100000,
            },
          })
            .then((testData) => {
              const testing = testData.data.listTestings.items;
              console.log(testing);
              const testingData = testing.filter((item) => {
                const _matchSites = assetId?.find(
                  (id) => id === item?.assetsID
                );
                if (_matchSites) {
                  return item;
                }
              });
              console.log(testingData);
              if (testingData.length > 0) {
                testingData.map((test) => {
                  const custom = JSON.parse(test.customFields);
                  const customFormatted =
                    custom &&
                    custom.map((item) => {
                      const key = Object.keys(item)[0];
                      const formattedValue = item[key];
                      return `${key} : ${formattedValue}`;
                    });
                  let customFieldsFormatted = [];
                  if (customFormatted !== null) {
                    customFieldsFormatted = customFormatted.join("\n");
                  } else {
                    console.error("customFormatted is null.");
                  }

                  testReport.push({
                    id: test.id,
                    createdAt: test.createdAt,
                    asset: test.assetsID,
                    testOn: test.testOn,
                    testResult: test.testResult,
                    nextTest: test.nextTestSchedule,
                    customfields: customFieldsFormatted,
                    remarks: test.additionalInformation,
                  });
                });
                const sort = testReport.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                console.log(sort);
                setAssetDemo(sort);
                console.log(testReport);
                setAssets(cloneDeep(sort.slice(0, countPerPage)));
                setLoading(false);
              } else {
                setAssetDemo(testReport);
                console.log(testReport);
                setAssets(cloneDeep(testReport.slice(0, countPerPage)));
                setLoading(false);
              }
            })
            .then((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          setAssetDemo(testReport);
          setAssets(cloneDeep(testReport.slice(0, countPerPage)));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    */}
  }

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setAssets(cloneDeep(assetDemo.slice(from, to)));
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  const handleAssets = (event) => {
    try {
      console.log(event.target.value);
      const selecteAsset = event.target.value;
      if (selecteAsset === "") {
        console.log("Not Selected Any Asset");
        setAssetS(false);
        setFilteredData([]);
        const data = filteredDataDate.length > 0 ? filteredDataDate : assetDemo;
        setAssets(cloneDeep(data.slice(0, countPerPage)));
      } else {
        const data = filteredDataDate.length > 0 ? filteredDataDate : assetDemo;
        const filterdata = data.filter((item) => item.asset === selecteAsset);
        console.log(filterdata);
        setFilteredData(filterdata);
        setAssets(cloneDeep(filterdata.slice(0, countPerPage)));
        setAssetS(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateRange = () => {
    if (selectedDateRange) {
      console.log(selectedDateRange);
      console.log(dateRange);
      console.log(dateRange[0]);
      console.log(dateRange[1]);
      console.log(sd);
      console.log(ed);
      const [startDate, endDate] = dateRange;
      console.log(startDate);
      console.log(endDate);
      const filteredData = assetDemo.filter((item) => {
        const createdAt = new Date(item.createdAt);
        return createdAt >= startDate && createdAt <= endDate;
      });
      console.log(filteredData);
    } else {
      console.log("no date range choosed");
    }
  };

  //-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="6">
                          <select
                            className="form-select"
                            onChange={handleAssets}
                          >
                            <option value="">Select Assets</option>
                            {assetsDropdown.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.fittingNumber}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md="6">
                          <DatePicker
                            className="form-control"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(d) => {
                              setDateRange(d);
                              setSelectedDateRange(d);
                              const [newStartDate, newEndDate] = d;
                              console.log(d);
                              console.log(newStartDate);
                              setSD(newStartDate);
                              console.log(newEndDate);
                              setED(newEndDate);
                              if (newStartDate && newEndDate) {
                                console.log(newEndDate);
                                console.log(newStartDate);
                                const data =
                                  filteredData.length > 0
                                    ? filteredData
                                    : assetDemo;
                                const filteredDatad = data.filter((item) => {
                                  const createdAt = new Date(item.createdAt);
                                  return (
                                    createdAt >= newStartDate &&
                                    createdAt <= newEndDate
                                  );
                                });
                                console.log(filteredDatad);
                                setDate(true);
                                setFilteredDataDate(filteredDatad);
                                setAssets(
                                  cloneDeep(
                                    filteredDatad.slice(0, countPerPage)
                                  )
                                );
                              } else {
                                const data =
                                  filteredData.length > 0
                                    ? filteredData
                                    : assetDemo;
                                setDate(false);
                                setFilteredDataDate([]);
                                setAssets(
                                  cloneDeep(data.slice(0, countPerPage))
                                );
                              }
                            }}
                            isClearable={true}
                            placeholderText="Enter Date Range"
                            dateFormat="dd-MM-yyyy"
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <H4>Testing Result</H4>
                        </Col>
                      </Row>
                      <Row>
                        <div className="card-block row">
                          <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                              <Table
                                className="table-striped  table-de"
                                id="report"
                              >
                                <thead className="custom-table-head">
                                  <tr>
                                    <th>Asset</th>
                                    <th>Test Date</th>
                                    <th>Test Result</th>
                                    <th>Next Testing Date</th>
                                    <th>Additional Info</th>
                                  </tr>
                                </thead>
                                <tbody className="trhover">
                                  {assets.map((item) => (
                                    <tr key={item.id}>
                                      <td>{getAssets(item.asset)}</td>
                                      <td>
                                        {moment(item.testOn).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>{item.testResult}</td>
                                      <td>
                                        {item.nextTest ? moment(item.nextTest).format(
                                          "DD/MM/YYYY"
                                        ) : "-"}
                                      </td>
                                      <td>{item.remarks}</td>
                                    </tr>
                                  ))}
                                  {assets.length === 0 && (
                                    <tr>
                                      <td className="text-center" colSpan="12">
                                        No Tests Found for the selected Site
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </div>
                      </Row>
                    </CardBody>
                    <CardFooter className="row">
                      <Pagination
                        className="p-t-10"
                        pageSize={countPerPage}
                        onChange={updatePage}
                        current={currentPage}
                        total={
                          assetS
                            ? filteredData.length
                            : date
                              ? filteredDataDate.length
                              : assetDemo.length
                        }
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                    </CardFooter>
                    <br></br>
                    <br></br>
                    <Col md="12" className="text-end">
                      <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                        Back
                      </Btn>
                    </Col>
                  </Card>
                </>
              )}
            </>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TestResultContains;
